.App {
  text-align: center;
  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-container {
  width: 400px;
  height: 400px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
}

.form-container .header {
  flex: 20%;
  display: grid;
  place-items: center;
}

.form-container .body {
  flex: 60%;
}

.form-container .footer {
  flex: 20%;
  display: flex;
  justify-content: center;
}

.form-container .footer button {
  border-radius: 7px;
  width: 100px;
  height: 40px;
  background-color: #61dafb;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 1px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px;
  margin: 5px;
}

.sign-up-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sign-up-container input {
  margin: 5px;
  width: 200px;
  height: 40px;
  padding-left: 5px;
  font-size: 20px;
}

.personal-info-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.personal-info-container input {
  margin: 5px;
  width: 200px;
  height: 40px;
  padding-left: 5px;
  font-size: 20px;
}

/* PROGRESS BAR */
.progressbar {
  width: 400px;
  height: 10px;
  background-color: white;
  margin-bottom: 50px;
}

.progressbar div {
  width: 33.3%;
  height: 100%;
  background-color: #61dafb;
}

.other-info-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.other-info-container input {
  margin: 5px;
  width: 200px;
  height: 40px;
  padding-left: 5px;
  font-size: 20px;
}

input {
  border: 2px solid #61dafb;
  border-radius: 5px;
}

input:focus {
  border: 3px solid #61dafb;
}